import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import Page404 from '../components/Page404';
import TenupSmart from "../ethereum/TenupSmart";
import web3 from "../web3";
import { swapUpdateRequest } from "../store/ActionCreators";


const mapStateToProps = (state) => ({
  authedUser: state.authedUser,
  swapTx: state.swapTx
})
const mapDispatchToProps = (dispatch) => ({
  // swapUpdateRequest: (s) => dispatch(swapUpdateRequest(s))
})

const PendingSwaps = (props) => {
  const [adrsAmt, setAdrsAmt] = useState([]);
  const [show, setShow] = useState(false);
  const copyString = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }


  const addOrRemoveAddress = (adrs, amt, tx, uid) => {
    var a = [];
    let wasPresent = false;
    adrsAmt.forEach(adrAm => {
      if (adrAm.address === adrs){
        wasPresent = true;
      }
      else{
        a.push(adrAm);
      }
    })
    if (!wasPresent){
      a.push({address: adrs, amount: amt, txId: tx, uid: uid});
    }
    setAdrsAmt(a);
  }
  const swap = async () => {
    // console.log(adrsAmt);
    // let addresses = [];
    // let amounts = [];
    // adrsAmt.forEach(a => {
    //   addresses.push(a.address);
    //   amounts.push(web3.web3.utils.toWei(a.amount));
    // })
    // console.log(addresses, amounts, props.authedUser.authedUser.address);
    // try{
    //   setShow(true);
    //   await TenupSmart.methods.multiTransfer(addresses, amounts).send({
    //     from: props.authedUser.authedUser.address
    //   })  
    //   setShow(false);
    //   props.swapUpdateRequest(adrsAmt);

    // }
    // catch(e){
    //   console.log(e);
    // }
  }

  if (props.authedUser.authedUser.address === process.env.REACT_APP_ADMIN_ADDRESS.toLowerCase()){
    return (
          <>
            <section className="zl_account_support_page">
                <HeadingModule name={'Pending Swaps'} />
                <div class="limiter">
                            <div class="container-table100">
                                <div class="wrap-table100">
                                    <div class="table100">
                                        <table>
                                            <thead>
                                                <tr class="table100-head">
                                                <th class="column1"></th>
                                                <th class="column2">Amount</th>
                                                <th class="column3">Received Tx Hash</th>
                                                <th class="column4">Receiving Address</th>
                                                <th class="column5">Status</th>
                                                {/* <th class="column5">Quantity</th>
                                                <th class="column6">Total</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.swapTx.swapTx.filter(s => s.status === "Pending").map((s, i) => {
                                                    return(
                                                        <tr>
                                                            <td>
                                                              <input onClick={() => addOrRemoveAddress(s.user.address, s.amount, s.tx_received, s.user._id)} type="checkbox" />
                                                            </td>
                                                            <td class="column1">{s.amount}</td>
                                                            <td class="column2" onClick={() => copyString(s.tx_received)} >{s.tx_received.substring(0,5)}...{s.tx_received.substring(59,64)}</td>
                                                            <td class="column3">{s.generated_address}</td>
                                                            <td class="column4">{s.status}</td>
                                                            {/* <td class="column5">1</td>
                                                            <td class="column6">$999.00</td> */}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <button onClick={() => swap()} style={{marginLeft: 'auto', marginRight: '20px', marginTop: '20px', backgroundColor: '#43C2F2', padding: '9px 25px', borderRadius: '12px', fontWeight: '600'}} >Swap</button>

                            </div>
                        </div>
                
                {/* <div className="zl_SecureBackup_heading">
                    <h3>How can we help you?</h3>
                </div> */}
                {/* <Form className="zl_account_support_input_list">
                    <Form.Group className="zl_account_support_input_items">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Type your email address here!" />
                    </Form.Group>
                    <Form.Group className="zl_account_support_input_items">
                        <Form.Label>Message</Form.Label>
                        <Form.Control type="text" placeholder="Type your Message here" />
                    </Form.Group>
                </Form>
                <div className="zl_securebackup_btn">
                    <Link to={'#'} className="mx-auto">Send</Link>
                </div> */}
            </section>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body style={{textAlign: 'center'}}>
                  <div className="modal-loader"></div>
                  <h5>Dont close the window until the amount is approved.</h5>
              </Modal.Body>
            </Modal>
        </>
    );
  }
  else{
    return(
      <Page404 />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingSwaps);
