import React, { useEffect, useState } from 'react';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import web3 from "../web3";
import STAKINGABI from "../ethereum/staking.json";
import TOKENABI from "../ethereum/erc20_abi.json";
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';
import StakingComponent from '../components/StakingSection/staking';
import RewardCalculatorComponent from '../components/StakingSection/rewardCalculator';
import { Modal, Button } from 'react-bootstrap';

const mapStateToProps = (state) => ({
  authedUser: state.authedUser,
  // swapTx: state.swapTx
})

const toHex = (amount) => '0x' + amount.toString(16);


const getContract = (web3, abi, address) => new web3.eth.Contract(abi, address);


const Staking = (props) => {

  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const getStakerInfo = async (address) => {
    let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
    let res = await STAKE.methods.stakers(address).call();
    console.log(res);
    if (res.stakedAmount > 0){
      let rew = await STAKE.methods.showRewards(address).call();
      setRewardAmount(parseInt(rew));
    }
    setStakedAmount(parseInt(res.stakedAmount));
  }


  useEffect(() => {
    if (props.authedUser.authedUser.address){
      getStakerInfo(props.authedUser.authedUser.address);
    }
    else{
      setStakedAmount(0);
    }
  }, [props.authedUser.authedUser]);

  const [rewardPerDay, setRewardPerDay] = useState(0);
  

  const showRewards = async () => {
    let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
    let a = await STAKE.methods.showRewards(props.authedUser.authedUser.address).call();
    // setRewardPerDay(web3.utils.fromWei(a));
    console.log("aekjfoqijmfoc", a);
  }

  useEffect(() => {
    if (props.authedUser.authedUser.address){
      showRewards()
    }
  }, [props.authedUser]);


  const claimReward = async () => {
    if (props.authedUser.authedUser.address){
      setShowModal(true);
      let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
      try{
          let a = await STAKE.methods.redeem().send({
              from : props.authedUser.authedUser.address
          });
          // setRewardPerDay(web3.utils.fromWei(a));
          console.log("aekjfoqijmfoc", a);
          getStakerInfo(props.authedUser.authedUser.address);
          setShowModal(false);
      }
      catch(e){
          setShowModal(false);
          alert('Failed');
      }
    }
    else{
      alert('Connect Wallet');
    }
  }

  const checkPerDayReward = async () => {
    let val = document.getElementById('perdayrew').value;
    if (val && parseFloat(val) > 0){
      val = new BigNumber(web3.web3.utils.toWei(val));
      val = toHex(val);
      let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
      let a = await STAKE.methods.calculatePerDayRewards(val).call();
      setRewardPerDay(web3.web3.utils.fromWei(a));
    }
  }
  
  return(
    <section style={{color: 'white'}} className="zl_settings_page">
      <HeadingModule name={'Virtual Staking'} />
      <div className='container'>
      <StakingComponent setShowModal={setShowModal} authedUser={props.authedUser} stakedAmount={stakedAmount} setStakedAmount={setStakedAmount} getStakerInfo={getStakerInfo} />
      <div className='row'>
        <div className='col-12'>
          <div className='zl_all_page_comman_content mt-3 col-12'>
            <div className="zl_chart_box_heading_date">
              <h2 className="zl_chart_box_heading">YOUR REWARD:</h2>
            </div>
            {stakedAmount > 0 ? (
              <>
                <div className='zl_wallet_chart_bottom_content' style={{justifyContent: 'space-between'}}>
                  <p>You've staked {stakedAmount/10**18} TUP. Your total claimable reward is {parseFloat(rewardAmount/10**18).toFixed(5)} TUP</p>
                  <div className="zl_wallet_chart_send_recive_btn">
                    <Button onClick={claimReward} className="zl_wallet_chart_recive_btn" >
                        <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                        </svg>
                        Claim
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className='zl_wallet_chart_bottom_content' style={{justifyContent: 'space-between'}}>
              <p>You do not have staked TUP yet. Stake your TUP to earn reward.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <RewardCalculatorComponent authedUser={props.authedUser} />
      </div>
      
      <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
      >
          <Modal.Body style={{textAlign: 'center'}}>
              <div className="modal-loader"></div>
              <h5>Dont close the window until the transaction succeeds.</h5>
          </Modal.Body>
      </Modal>
      {/* <div>
        Enter Stake Amount: <input type='number' id='stakeamt' />
        <button onClick={stakeToken}>Stake</button>
      </div> */}
      {/* <div>
        <button onClick={unStakeToken}>UnStake</button>
      </div>
      <div>
        Your Reward: 0
        <button onClick={claimReward}>Claim</button>
      </div>
      <h3>Calculate Rewards per day:</h3>
      <input type='number' id='perdayrew' />
      <button onClick={checkPerDayReward}>Check</button>
      <p>{rewardPerDay ? 'Your reward per day will be: ' + parseFloat(rewardPerDay).toFixed(5) : ''}</p> */}

    </section>
  );
}

export default connect(mapStateToProps, null)(Staking);