import React, { useEffect, useState } from 'react';
import web3 from '../../web3';
import { Button } from 'react-bootstrap';
import STAKINGABI from "../../ethereum/staking.json";
import TOKENABI from "../../ethereum/erc20_abi.json";
import BigNumber from 'bignumber.js';


const toHex = (amount) => '0x' + amount.toString(16);
const getContract = (web3, abi, address) => new web3.eth.Contract(abi, address);


const isApproved = async (tok, owner, approvedAddress, totalAmount) => {
    try{
      let r = await tok.methods.allowance(owner, approvedAddress).call();
      console.log(r);
      if (parseInt(r) <= totalAmount){
        let val = new BigNumber(web3.web3.utils.toWei("10000000000"));
        let finPrice = toHex(val);
        let a = await tok.methods.approve(approvedAddress, finPrice).send({
          from: owner
        });
        console.log(a);
        return true;
      }
      else{
        return true;
      }
    }
    catch(e){
      console.log('Approve Issue', e);
      return false;
    }
  }

export default function Staking({authedUser, stakedAmount, setStakedAmount, getStakerInfo, setShowModal}){
    const handleToggle = () => {}

    const [stakersActive, setStakersActive] = useState(0);
    const [currentAPY, setCurrentAPY] = useState(0);
    const [totalStaked, setTotalStaked] = useState(0);

    const getStats = async () => {
        let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
        let totalToks = await STAKE.methods.totalStaked().call();
        let apy = await STAKE.methods.currentAPY().call();
        let activeSta = await STAKE.methods.stakersActive().call();
        setStakersActive(activeSta);
        setCurrentAPY(apy/100);
        setTotalStaked(totalToks/(10**18));
    }

    useEffect(() => {
        getStats();
    }, []);

    const stakeToken = async () => {
        if (authedUser.authedUser.address){
            let val = document.getElementById('stakeamt').value;
            if (val){
                if (parseFloat(val) >= 50){
                    setShowModal(true);
                    val = new BigNumber(web3.web3.utils.toWei(val));
                    val = toHex(val);
                    let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
                    let TOKEN = getContract(web3.web3, TOKENABI, process.env.REACT_APP_STAKING_TOKEN_ADDRESS);
                    let blnc = await TOKEN.methods.balanceOf(authedUser.authedUser.address).call();
                    if (parseInt(blnc) > val){
                        let app = await isApproved(TOKEN, authedUser.authedUser.address, process.env.REACT_APP_STAKING_ADDRESS, val);
                        if (app){
                        await STAKE.methods.StakeAmount(val).send({
                            from : authedUser.authedUser.address
                        });
                        getStakerInfo(authedUser.authedUser.address);
                        getStats();
                        setShowModal(false);
                        }
                        else{
                            setShowModal(false);
                            alert('Tokens not approved');
                        }
                    }
                    else{
                        setShowModal(false);
                        alert('Insufficient Tokens');
                    }
                }
                else{
                    alert('Minimum stake amount: 50');
                }
            }
            else{
                alert('Enter amount to stake');
            }
        }
        else{
            alert('Connect Wallet');
        }
    }

    
    const unstakeToken = async () => {
        if (authedUser.authedUser.address){
            setShowModal(true);
            let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
            try{
                let a = await STAKE.methods.endStake().send({
                    from : authedUser.authedUser.address
                });
                // setRewardPerDay(web3.utils.fromWei(a));
                console.log("aekjfoqijmfoc", a);
                getStakerInfo(authedUser.authedUser.address);
                getStats();
                setShowModal(false);    
            }
            catch(e){
                setShowModal(false);
                alert('Failed');
            }
        }
        else{
           alert('Connect Wallet');
        }
    }

    return(
        <div className='row'>
            <div className='col-6'>
                <div className="zl_all_page_comman_content col-12">
                    <div className="zl_chart_box_heading_date">
                        <h2 className="zl_chart_box_heading">Staking</h2>
                    </div>

                    <div className="zl_wallet_chart_bottom_content">
                        {stakedAmount > 0 ? (
                            <div className="zl_all_page_comman_total_price">
                                <p className="zl_all_page_total_price_heading">Total TUP you have staked </p>
                                <h2 className="zl_all_page_total_price_text" style={{
                                    marginTop: '15px',
                                    background: 'none',
                                }} >{stakedAmount/(10**18)}</h2>
                            </div>
                    
                        ) : (
                            <div className="zl_all_page_comman_total_price">
                                <p className="zl_all_page_total_price_heading">Enter amount to stake (minimum 50): </p>
                                <input className="zl_all_page_total_price_text" id="stakeamt" style={{
                                    marginTop: '15px',
                                    background: 'none',
                                }} />
                            </div>
                        )}
                    </div>
                    <div className='zl_wallet_chart_bottom_content' style={{justifyContent: 'flex-end'}}>
                        <div className="zl_wallet_chart_send_recive_btn">
                        {stakedAmount > 0 ? (
                            <Button className="zl_wallet_chart_recive_btn" onClick={unstakeToken}>
                                <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                </svg>
                                Unstake
                            </Button>
                        ):(
                            <Button className="zl_wallet_chart_send_btn" onClick={stakeToken}>
                                <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                </svg>
                                Stake
                            </Button>
                        )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <div className='col-12 zl_all_page_comman_content' style={{height: '100%'}}>
                    <div className="zl_chart_box_heading_date">
                        <h2 className="zl_chart_box_heading">Statistics</h2>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                        <p className="zl_chart_box_heading">Total Stakers</p>
                        <h6>{stakersActive} Stakers</h6>
                    </div>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                        <p className="zl_chart_box_heading">Current APY</p>
                        <h6>{currentAPY} %</h6>
                    </div>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                        <p className="zl_chart_box_heading">Total Staked</p>
                        <h6>{totalStaked} TUP</h6>
                    </div>
                </div>
            </div>
        </div>
    
    );
}