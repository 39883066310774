import React, { useState } from 'react';
import web3 from '../../web3';
import { Button, FormControl } from 'react-bootstrap'
import STAKINGABI from "../../ethereum/staking.json";
import BigNumber from 'bignumber.js';

const toHex = (amount) => '0x' + amount.toString(16);
const getContract = (web3, abi, address) => new web3.eth.Contract(abi, address);

export default function Staking(props){
  const [stakeToken, setStakeToken] = useState('');
  const [reward, setReward] = useState('');
  
  const calculateRew = async () => {
    let val = document.getElementById('rewardamt').value;
    if (val){
      setStakeToken(val);
      val = new BigNumber(web3.web3.utils.toWei(val));
      val = toHex(val);
      let STAKE = getContract(web3.web3, STAKINGABI, process.env.REACT_APP_STAKING_ADDRESS);
      let res = await STAKE.methods.calculatePerDayRewards(val).call();
      console.log(res);
      setReward(res/(10**18));  
    }
  }
  return(
    <div className='row'>
      <div className="mt-3 col-12">
        <div className='zl_all_page_comman_content col-12'>
          <div className="zl_chart_box_heading_date">
              <h2 className="zl_chart_box_heading">Reward Calculator</h2>
          </div>

          <div className="zl_wallet_chart_bottom_content">
              <div className="zl_all_page_comman_total_price">
                  <p className="zl_all_page_total_price_heading">Enter amount of TUP staked </p>
                  <input className="zl_all_page_total_price_text" id="rewardamt" style={{
                    marginTop: '15px',
                    background: 'none',
                  }} type='number'/>
              </div>
              <div className="zl_wallet_chart_send_recive_btn">
                  <Button className="zl_wallet_chart_send_btn" onClick={calculateRew}>
                      Calculate
                  </Button>
                  </div>
          </div>
          {stakeToken && (
            stakeToken >= 50 ? (
              <div>
                <p>Your reward upon staking {stakeToken} TUP will be {parseFloat(reward).toFixed(5)} TUP/day</p>
                <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                  <p className="zl_chart_box_heading">Annually</p>
                  <h6>{parseFloat(reward*365).toFixed(5)} TUP</h6>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                  <p className="zl_chart_box_heading">Semi Annually</p>
                  <h6>{(parseFloat(reward*365)/2).toFixed(5)} TUP</h6>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="mb-2">
                  <p className="zl_chart_box_heading">Quarterly</p>
                  <h6>{(parseFloat(reward*365)/4).toFixed(5)} TUP</h6>
                </div>
                </div>
              </div>
            ) : (
              <p>You cannot stake less than 50 TUP</p>
            )
          )}
        </div>
      </div>
    </div>
  );
}