import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { getSwapTx, logInRequest, logout } from './../../../store/ActionCreators';
import web3 from "../../../web3";
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import MetamaskLogo from '../metamask.png';
import WalletConnectLogo from '../walletconnect.png';

// import { mapStateToProps } from './mappers';

//  Create WalletConnect Provider
const providerBsc = new WalletConnectProvider({
    rpc: {
      56: "https://bsc-dataseed.binance.org/",
      97: "https://data-seed-prebsc-1-s1.binance.org:8545",
      1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
      3: "https://data-seed-prebsc-1-s1.binance.org:8545",
      qrcode: true,
      pollingInterval: 12000,
    },
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID)
  });

const mapDispatchToProps = (dispatch) => ({
    logInRequest: (address) => dispatch(logInRequest(address)),
    // getSwapTx: (address) => dispatch(getSwapTx(address)),
    logout: () => dispatch(logout())
});
const mapStateToProps = (state) => ({
    authedUser: state.authedUser
});
  

const HeadingModule = (props) => {
    const [errDisplay, setErrorDisplay] = useState(false);
    const [statusErr, setStatusErr] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // const connectMetamask = async () => {
    //     console.log('here');
    //     if (!window.ethereum){
    //         setStatusErr('Install Metamask');
    //         setErrorDisplay(true);
    //     }
    //     else if (window.ethereum.chainId !== process.env.REACT_APP_CHAIN_ID){
    //         setStatusErr('Connect to Mainnet');
    //         setErrorDisplay(true);
    //     }
    //     else{
    //         var accs = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         if (accs.length > 0){
    //           props.logInRequest(accs[0])
    //           .then(r => {
    //               console.log(r);
    //               props.getSwapTx(accs[0]);
    //           })
    //         }
    //         else{
    //             setStatusErr('Connect to Metamask');
    //             setErrorDisplay(true);
    //         }
    //     }
    // }

    const connectWalletConnect = async () => {
        window.localStorage.removeItem('walletconnect');
        // await providerBsc.disconnect();
        setShowModal(false);
        console.log("herereadsaca")
        await providerBsc.enable();
        console.log("herereadsaca")
        const result = await providerBsc.request("eth_chainId");
        console.log("herereadsaca")
        console.log(result);
        const web33 = new Web3(providerBsc);
        web3.web3 = web33;
    
        var accs = await web33.eth.getAccounts();
        if (accs.length > 0) {
          console.log(accs);
            props.logInRequest(accs[0])
            .then(r => {
                console.log(r);
                // props.getSwapTx(accs[0]);
            });
        } else {
          setStatusErr('Connect to Metamask');
          setErrorDisplay(true);
        }
    }

    const connectMetamask = async () => {
        console.log('here');
        setShowModal(false);
        if (!window.ethereum){
            setStatusErr('Install Metamask');
            setErrorDisplay(true);
        }
        else{
            console.log(parseInt(window.ethereum.chainId) !== parseInt(process.env.REACT_APP_BASE_URL), parseInt(window.ethereum.chainId), process.env.REACT_APP_BASE_URL)
            if (parseInt(window.ethereum.chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)){
                setStatusErr('Connect to Mainnet');
                setErrorDisplay(true);
            }
            else{
                console.log('herhehrheheh')
                var accs = await window.ethereum.request({ method: 'eth_requestAccounts', });
                web3.web3 = new Web3(window.ethereum);
                console.log(accs);
                if (accs.length > 0){
                    props.logInRequest(accs[0])
                    .then(r => {
                        console.log(r);
                        // props.getSwapTx(accs[0]);
                    })
                }
                else{
                    setStatusErr('Connect to Metamask');
                    setErrorDisplay(true);
                }
            }
        }
    //     else if (window.ethereum.chainId !== process.env.REACT_APP_CHAIN_ID){
    //         setStatusErr('Connect to Mainnet');
    //         setErrorDisplay(true);
    //     }
    //     else{
    //         // var accs = await web3.eth.getAccounts();
    //         var accs = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         if (accs.length > 0){
    //           props.logInRequest(accs[0])
    //           .then(r => {
    //               console.log(r);
    //               props.getSwapTx(accs[0]);
    //           })
    //         }
    //         else{
    //             setStatusErr('Connect to Metamask');
    //             setErrorDisplay(true);
    //         }
    //     }
    }

    useEffect(() => {
        if (window.ethereum){
            connectMetamask();
        }
        else{
            window.addEventListener('ethereum#initialized', connectMetamask, {
                once: true,
            });
            setTimeout(connectMetamask, 3000)
        }
    }, []);

    useEffect(() => {
        if (window.ethereum){
            window.ethereum.on('accountsChanged', function (accounts) {
                if (accounts.length > 0){
                  if (Object.keys(props.authedUser.authedUser).length === 0 | props.authedUser.authedUser.address !== accounts[0].toLowerCase()){
                    console.log('newAcc: ', accounts[0], 'prevAcc: ', props.authedUser.authedUser.address);
                      props.logout();
                      props.logInRequest(accounts[0])
                      .then(r => {
                          console.log(r);
                        //   props.getSwapTx(accounts[0]);
                      })
                  }
                }
                else{
                  props.logout();
                }
            })
        }
    })
    
    return (
        <>
        {/* style={{color: '#eaebee'}} */}
            <div className="zl_all_page_heading_section">
                <div className="zl_all_page_heading">
                    <h1 >{props.name}</h1>
                    <p style={{fontSize: '15px', color: '#a1a7bb'}}>Tenup Nation: For Progress, Investment & Success</p>
                </div>
                <div className="zl_all_page_notify_logout_btn">
                    {/* <Dropdown className="zl_all_page_notification_dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5 0C3.92954 0 2.98173 0.691642 2.65518 1.71108L0 10H10L7.34482 1.71108C7.01827 0.691641 6.07046 0 5 0ZM3.5 11C3.22386 11 3 11.2239 3 11.5C3 11.7761 3.22386 12 3.5 12H6.5C6.77614 12 7 11.7761 7 11.5C7 11.2239 6.77614 11 6.5 11H3.5Z" fill="white" />
                            </svg>
                            15
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <div className="zl_all_page_notification_body">
                                <h2 className="zl_all_page_notification_heading">NOTIFICATIONS</h2>
                                <div className="zl_all_page_notification_list">
                                    <img src="assets/image/Avatar.png" alt="user-img" className="zl_all_page_notification_user_img" />
                                    <div className="zl_all_page_notification_list_body">
                                        <h3 className="zl_all_page_notification_list_heading">
                                            Clifford Hale
                                            <span>Sent you a message</span>
                                        </h3>
                                        <p className="zl_all_page_notification_list_peregraph">
                                            Lorem Ipsum is simply dummy text of industry.?
                                        </p>
                                        <span className="zl_all_page_notification_list_time">2 hours ago</span>
                                    </div>
                                    <Button className="zl_all_page_notification_list_more_btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="zl_all_page_notification_list">
                                    <div className="zl_all_page_notification_user_img zl_user_green_bg">L</div>
                                    <div className="zl_all_page_notification_list_body">
                                        <h3 className="zl_all_page_notification_list_heading">
                                            Lottie Marsh
                                            <span>Sent you a coin</span>
                                        </h3>
                                        <p className="zl_all_page_notification_list_peregraph">
                                            Lorem Ipsum is simply dummy text of industry.?
                                        </p>
                                        <div className="zl_all_page_notification_list_price">
                                            <p>+380.234<span>LTC</span></p>
                                            <Link to={'/dashboard'}>Open my wallet</Link>
                                        </div>
                                        <span className="zl_all_page_notification_list_time">3 hours ago</span>
                                    </div>
                                    <Button className="zl_all_page_notification_list_more_btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="zl_all_page_notification_list">
                                    <div className="zl_all_page_notification_user_img zl_user_pink_bg">B</div>
                                    <div className="zl_all_page_notification_list_body">
                                        <h3 className="zl_all_page_notification_list_heading">
                                            BTC
                                            <span>News</span>
                                        </h3>
                                        <p className="zl_all_page_notification_list_peregraph">
                                            Lorem Ipsum is simply dummy text of industry.?
                                        </p>
                                        <div className="zl_all_page_notification_list_price">
                                            <Sparklines data={[0, 5, 0, 15, 12, 14]} margin={6} className="zl_add_currency_mini_chart">
                                                <SparklinesLine style={{ strokeWidth: 10, stroke: "#309AFF", fill: "none", curve: "smooth" }} />
                                                <SparklinesSpots size={4}
                                                    style={{ stroke: "#309AFF", strokeWidth: 3, fill: "white" }} />
                                            </Sparklines>
                                            <p>+39.69%<span>LTC</span></p>
                                            <Link to={'/dashboard'}>Trade now</Link>
                                        </div>
                                        <span className="zl_all_page_notification_list_time">3 hours ago</span>
                                    </div>
                                    <Button className="zl_all_page_notification_list_more_btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="zl_all_page_notification_list">
                                    <img src="assets/image/Avatar.png" alt="user-img" className="zl_all_page_notification_user_img" />
                                    <div className="zl_all_page_notification_list_body">
                                        <h3 className="zl_all_page_notification_list_heading">
                                            Clifford Hale
                                            <span>Sent you a message</span>
                                        </h3>
                                        <p className="zl_all_page_notification_list_peregraph">
                                            Lorem Ipsum is simply dummy text of industry.?
                                        </p>
                                        <div className="zl_all_page_notification_list_price">
                                            <p>+380.234<span>LTC</span></p>
                                            <Link to={'/dashboard'}>Open my wallet</Link>
                                        </div>
                                        <span className="zl_all_page_notification_list_time">2 hours ago</span>
                                    </div>
                                    <Button className="zl_all_page_notification_list_more_btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="zl_all_page_notification_more_list_btn">
                                    <Link to={'/dashboard'}>See All</Link>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                     */}
                    {(Object.keys(props.authedUser.authedUser).length > 0) ? (
                    <button className="connect-wallet">
                        <span>
                            {props.authedUser.authedUser.address.substring(0,5)}...{props.authedUser.authedUser.address.substring(38,42)}
                        </span>
                        <span className="eth-balancee">
                            {parseFloat(web3.web3.utils.fromWei(props.authedUser.authedUser.balance)).toFixed(3)} TUP
                        </span>
                    </button>
                    ) : (
                        <button className="connect-wallet" onClick={() => setShowModal(true)}>CONNECT WALLET</button>
                    )}
                </div>
            </div>
            
            <Snackbar open={errDisplay} autoHideDuration={5000} variant="error" onClose={() => setErrorDisplay(false)}>
                <Alert onClose={() => setErrorDisplay(false)} severity="error" variant="filled">
                    {statusErr}
                </Alert>
            </Snackbar>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
		        <Modal.Body>
                    <h4>Choose Your Wallet</h4>
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <div style={{border: '1px solid grey', borderRadius: '10px', padding: '25px', cursor: 'pointer'}} onClick={connectMetamask}>
                            <img src={MetamaskLogo} style={{height: '60px', width: '60px'}} /> 
                        </div>
                        <div style={{border: '1px solid grey', borderRadius: '10px', padding: '25px', cursor: 'pointer'}} onClick={connectWalletConnect}>
                            <img src={WalletConnectLogo} style={{height: '60px', width: '60px'}} /> 
                        </div>
                    </div>
		        </Modal.Body>
		    </Modal>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadingModule);
