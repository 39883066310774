import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import Clipboard from 'clipboard';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { updateNonce } from "../store/ActionCreators";
import TenupSmart from '../ethereum/TenupSmart';
import web3 from "../web3";
import './main.css';
import './util.css';
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const mapStateToProps = (state) => ({
    authedUser: state.authedUser,
    swapTx: state.swapTx
})

const mapDispatchToProps = (dispatch) => ({
    updateNonce: (user) => dispatch(updateNonce(user))
})

const SwapModule = ({themHandler, authedUser, updateNonce, swapTx}) => {

    new Clipboard('.copy-btn');

    const [color, setColor] = useState(true);
    const [addressAmount, setAdrsAmt] = useState([]);
    const [comingSoonInfo, setComingSoonInfo] = useState(false);
    
    const addOrRemoveAddress = () => {
        
    }
    const txExists = () => {
        var a = false;
        swapTx.swapTx.forEach((s) => {
            if (s.generated_address === authedUser.authedUser.generateAddress){
                a = true;
            }
        })
        return a;
    }
    useEffect(() => {
        if(typeof window !== 'undefined') {
            setColor(localStorage.getItem("themColor") === "zl_light_theme_active" ? false : localStorage.getItem("themColor") !== null && true);
        }
    }, []);

    const generateAddress = () => {
        setComingSoonInfo(true);
        // if (authedUser.authedUser.address){
        //     fetch(`${process.env.REACT_APP_BASE_URL}/users/generateAddress/${authedUser.authedUser.address}`)
        //     .then(res => res.json())
        //     .then(async res => {
        //         console.log(res);
        //         if (res.success){
        //             var balance = await TenupSmart.methods.balanceOf(authedUser.authedUser.address).call();
        //             res.user.balance = balance;
        //             updateNonce(res.user);
        //         }
        //     })
        // }
        // else{
        //     alert('Connect Wallet');
        // }
    }
    const copyString = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
    return(
        <>
            <section className="zl_settings_page">
                <HeadingModule name={'Swap'} />
                <div className="zl_setting_list">
                    <div className="zl_setting_list_items" style={{backgroundColor: '#353f5e', boxShadow: '-10px 48.6px 140px rgba(23, 18, 43, 0.45)'}}>
                        <div className="zl_setting_items_heading_peregraph" >
                            <h2 style={{color: '#eaebee'}}>How it works?</h2>
                            <p style={{color: '#a1a7bb', fontSize: '15px', lineHeight: '30px', fontWeight: 'normal'}}>A very simple and easy method for swapping. <br></br>
                            Follow the Steps: <br/>
                            1. Connect you metamask wallet <br/>
                            2. Press "Generate Deposit Address"<br/>
                            3. Send the correct swapping TUP on that generated address.<br/>
                            4. After depositing old Tenup on the below generated address. You will receive TenupSmart with 1:1 ratio.
                            </p><br/>
                            {/* <p style={{color: '#dc1818', fontWeight:700, fontSize: '14px'}}>"Swap will be live in 24 hours after presale start"</p> */}
                            <h3 style={{color: '#eaebee'}}>Note: You will receive TenupSmart after 24 hours, It may take longer depending upon the traffic. Thank you.</h3>
                        </div>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {/* <input type="number" id="swap-amount" placeholder="TUP Amount to Swap"></input> */}
                        <div style={{display: 'inline-block', position: 'relative'}}>
                            <button class="copy-btn" data-clipboard-target="#swap-address">
                                <img className="clippy" src="assets/image/clippy.svg" alt="clipboard"></img>
                            </button>
                            {authedUser.authedUser.generatedAddress ? (
                            <input type="text" id="swap-address" placeholder="Generated Address will be shown here." value={authedUser.authedUser.generatedAddress}></input>
                            ) : (
                                <input type="text" id="swap-address" placeholder="Generated Address will be shown here."></input>
                            )}
                        </div><br/>
                        <button style={{display: authedUser.authedUser.generatedAddress ? 'none' : 'inline-block'}} onClick={generateAddress} className="swap-btn">Generate Deposit Address</button>
                        {/* Below Button is just for copy clipboard  */}
                    </div>
                </div>
                {(authedUser.authedUser.address === process.env.REACT_APP_ADMIN_ADDRESS.toLowerCase()) ? (txExists() && (
                    <div class="limiter">
                        <div class="container-table100">
                            <div class="wrap-table100">
                                <div class="table100">
                                    <table>
                                        <thead>
                                            <tr class="table100-head">
                                            <th class="column1"></th>
                                            <th class="column2">Amount</th>
                                            <th class="column3">Received Tx Hash</th>
                                            <th class="column4">Receiving Address</th>
                                            <th class="column5">Status</th>
                                            {/* <th class="column5">Quantity</th>
                                            <th class="column6">Total</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {swapTx.swapTx.map((s, i) => {
                                                return(
                                                    <tr>
                                                        <td> {(i%2 === 0) && (
                                                            <input onClick={() => addOrRemoveAddress(s.user.address, s.amount)} type="checkbox" />
                                                        )} </td>
                                                        <td class="column1">{s.amount}</td>
                                                        <td class="column2" onClick={() => copyString(s.tx_received)} >{s.tx_received.substring(0,5)}...{s.tx_received.substring(59,64)}</td>
                                                        <td class="column3">{s.generated_address}</td>
                                                        <td class="column4">{s.status}</td>
                                                        {/* <td class="column5">1</td>
                                                        <td class="column6">$999.00</td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                )) : ((swapTx.swapTx.length > 0) && (
                    <div class="limiter">
                        <div class="container-table100">
                            <div class="wrap-table100">
                                <div class="table100">
                                    <table>
                                        <thead>
                                            <tr class="table100-head">
                                            <th class="column1">Amount</th>
                                            <th class="column2">Received Tx Hash</th>
                                            <th class="column3">Receiving Address</th>
                                            <th class="column4">Status</th>
                                            {/* <th class="column5">Quantity</th>
                                            <th class="column6">Total</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {swapTx.swapTx.map(s => {
                                                return(
                                                    <tr>
                                                        <td class="column1">{s.amount}</td>
                                                        <td class="column2" onClick={() => copyString(s.tx_received)} >{s.tx_received.substring(0,5)}...{s.tx_received.substring(59,64)}</td>
                                                        <td class="column3">{s.generated_address}</td>
                                                        <td class="column4">{s.status}</td>
                                                        {/* <td class="column5">1</td>
                                                        <td class="column6">$999.00</td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                ))}
            </section>
            <Snackbar open={comingSoonInfo} autoHideDuration={4000} variant="info" onClose={() => setComingSoonInfo(false)}>
                <Alert onClose={() => setComingSoonInfo(false)} severity="info" variant="filled">
                Coming Soon
                </Alert>
            </Snackbar>
        </>
    
    );    
}

export default connect(mapStateToProps, mapDispatchToProps)(SwapModule);
