import { routes } from '../shared/constants';

// import Homepage from './Homepage';
import Dashboard from './Dashboard';
// import Welcome from './Welcome';
import WelcomeModify from './Welcome';
import LogIn from './LogIn';
import Portfolio from './Portfolio';
import AddCurrency from './AddCurrency';
import Wallets from './Wallets';
import History from './History';
import Swap from './Swap';
import SecureBackup from './SecureBackup';
import Setting from './Settings';
import RestoreWallet from './RestoreWallet';
import Currency from './Currency';
import AccountSupport from './AccountSupport';
import PendingSwaps from './PendingSwaps';
import SuccessfulSwaps from './SuccessfulSwaps';
import Presale from './Presale';
import Staking from './Staking';



export default {
	// [routes.homepage]: Homepage,
	// [routes.dashboardpage]: Dashboard,
	[routes.welcomepage]: WelcomeModify,
	[routes.loginpage]: LogIn,
	[routes.portfoliopage]: Portfolio,
	[routes.addcurrencypage]: AddCurrency,
	[routes.walletspage]: Wallets,
	[routes.historypage]: History,
	[routes.swappage]: Swap,
	[routes.securebackuppage]: SecureBackup,
	[routes.settingspage]: Setting,
	[routes.restorewalletpage]: RestoreWallet,
	[routes.currencypage]: Currency,
	[routes.virtualmining]: Staking,
	// [routes.accountsupportpage]: AccountSupport,
	[routes.pendingswaps]: PendingSwaps,
	[routes.successfulswaps]: SuccessfulSwaps,
	[routes.presale]: Presale,
};